














































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useWindowSize } from '@vueuse/core';

export default defineComponent({
  name: 'Recipe',
  props: {
    title: {
      type: String,
      default: ''
    },
    imageHolder: {
      type: Object,
      default: () => ({})
    },
    ingredients: {
      type: Object,
      default: () => ({})
    },
    preparation: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    const isRecipeImage = (): boolean => !!props.imageHolder?.poi?.image;
    const isIngredients = computed(() => !!props.ingredients?.data?.length);
    const isPreparation = computed(() => !!props.preparation?.data?.length);

    const windowSize = useWindowSize({
      includeScrollbar : false
    });
    const styleWidth = computed(() => '--content-width:' + windowSize.width.value + 'px');

    return {
      isRecipeImage,
      isIngredients,
      isPreparation,
      isVisibleContent: computed(() => isIngredients.value || isPreparation.value),
      styleWidth,
      sources: [
        {
          aspect: '2.56:1',
          height: 300,
          media: '(max-width: 767px)',
        },
        {
          aspect: '2.57:1',
          height: 480,
          media: '(min-width: 768px)',
        },
      ],
    };
  }
});
